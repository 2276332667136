// extracted by mini-css-extract-plugin
export var backgroundAnimation = "winter-layout-module--backgroundAnimation--86134";
export var contact = "winter-layout-module--contact--42277";
export var container = "winter-layout-module--container--34069";
export var content = "winter-layout-module--content--4bd8b";
export var errorBlink = "winter-layout-module--error-blink--6fcb8";
export var expired = "winter-layout-module--expired--64156";
export var hasHeader = "winter-layout-module--has-header--69e0f";
export var header = "winter-layout-module--header--25273";
export var headerContent = "winter-layout-module--header-content--920c7";
export var innerContainer = "winter-layout-module--inner-container--397aa";
export var layout = "winter-layout-module--layout--29781";
export var left = "winter-layout-module--left--090f4";
export var leftBottom = "winter-layout-module--left-bottom--a7214";
export var mainLayout = "winter-layout-module--main-layout--cdad7";
export var right = "winter-layout-module--right--d8466";
export var rightBottom = "winter-layout-module--right-bottom--9cc5d";
export var thanks = "winter-layout-module--thanks--b0df1";