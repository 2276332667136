import React from 'react';

import {
    container,
    title,
    accent,
    icon,
    button,
    subtitle as subtitleClass,
    smallSubtitle as smallSubtitleClass,
} from './contest-thank-you.module.scss';

import IconSent from '../../assets/images/svg/icon_completed.svg';

import Button from '../atoms/button';

export interface IContestThankYouProps {
    className?: string;
    titleFirstLine?: string;
    titleSecondLine?: string;
    subtitle?: string;
    smallSubtitle?: string;
    buttonUrl?: string;
    buttonLabel?: string;
}

const ContestThankYou: React.FC<IContestThankYouProps> = ({
    className = '',
    titleSecondLine,
    titleFirstLine,
    subtitle,
    smallSubtitle,
    buttonUrl,
    buttonLabel,
}) => {
    return (
        <div className={`${container} ${className}`}>
            <IconSent className={`${icon} icon`} />
            {(titleFirstLine || titleSecondLine) && (
                <h1 className={`${title} title`}>
                    {titleFirstLine && <span>{titleFirstLine}</span>}
                    {titleSecondLine && (
                        <span className={`${accent} accent`}>{titleSecondLine}</span>
                    )}
                </h1>
            )}
            {subtitle && <p className={`${subtitleClass} subtitle`}>{subtitle}</p>}
            {buttonUrl && buttonLabel && (
                <Button className={`${button} button`} as={'link'} to={buttonUrl}>
                    {buttonLabel}
                </Button>
            )}
            {smallSubtitle && (
                <p className={`${smallSubtitleClass} small-subtitle`}>{smallSubtitle}</p>
            )}
        </div>
    );
};

export default ContestThankYou;
