import React from 'react';
import { graphql } from 'gatsby';

import { container, header, icon, title, text } from './expired.module.scss';

import WarningIcon from '../../../assets/images/svg/warning.svg';

import { IPage } from '../../../models/page.model';
import { useI18next } from '../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import WinterLayout from '../../../layouts/winter-layout';

interface IWinterContestExpired {
    readonly data: {
        page: IPage | null;
    };
}

const Expired: React.FC<IWinterContestExpired> = ({ data }) => {
    const { page } = data;
    const { t } = useI18next();

    return (
        <WinterLayout pageType={'expired'} page={page} className={container}>
            <div className={header}>
                <WarningIcon className={icon} />
                <h1 className={title}>{t('winter.contest.expired.title')}</h1>
                <p className={text}>{t('winter.contest.expired.description')}</p>
            </div>
        </WinterLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default Expired;
