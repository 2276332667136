import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
    layout,
    container,
    innerContainer,
    expired,
    content,
    header,
    hasHeader,
    thanks,
    headerContent,
    mainLayout,
    contact,
    left,
    right,
    leftBottom,
    rightBottom,
} from './winter-layout.module.scss';
import { grid } from '../styles/grid.module.scss';

import staticFiles from '../config/static-files';

import MainLayout, { IMainLayoutProps } from './main-layout';
import ContestContact from '../components/organisms/contest-contact';
import ContestThankYou, { IContestThankYouProps } from '../components/organisms/contest-thank-you';

interface IWinterLayout extends IMainLayoutProps {
    pageType: 'main' | 'thank-you' | 'winner' | 'expired' | 'closed';
    children?: React.ReactNode;
    headerProps?: IContestThankYouProps;
    showSideImages?: boolean;
}

const leftImg = '../assets/images/winter-contest/left.png';
const rightImg = '../assets/images/winter-contest/right.png';
const bottomLeftImg = '../assets/images/winter-contest/bottom-left.png';
const bottomRightImg = '../assets/images/winter-contest/bottom-right.png';

const WinterLayout: React.FC<IWinterLayout> = ({
    children,
    pageType,
    className = '',
    headerProps,
    showSideImages = false,
    ...rest
}) => {
    return (
        <MainLayout
            {...rest}
            className={`${layout} ${headerProps ? hasHeader : ''}`}
            containerClassName={mainLayout}
        >
            <div className={`${headerProps ? grid : ''} ${header}`}>
                <div className={headerContent}>
                    {headerProps && <ContestThankYou {...headerProps} className={thanks} />}
                </div>
            </div>
            <div
                className={`${innerContainer} ${
                    pageType === 'expired' || pageType === 'closed' ? expired : ''
                }`}
            >
                <div className={`${container} ${grid}`}>
                    <div className={`${className} ${content}`}>{children}</div>
                    <ContestContact
                        className={contact}
                        regulationsUrl={staticFiles.winterContestRegulations}
                    />
                </div>
                <>
                    <StaticImage src={leftImg} alt={''} className={left} />
                    <StaticImage src={rightImg} alt={''} className={right} />
                    {pageType === 'main' && (
                        <>
                            <StaticImage src={bottomLeftImg} alt={''} className={leftBottom} />
                            <StaticImage src={bottomRightImg} alt={''} className={rightBottom} />
                        </>
                    )}
                </>
            </div>
        </MainLayout>
    );
};

export default WinterLayout;
